import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes, FaHome, FaFileCode, FaLightbulb, FaClipboardList, FaComments, FaTelegram } from 'react-icons/fa';
import './Navbar.css';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
  
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const navLinks = [
        { to: '/', icon: FaHome, text: 'Home' },
        { to: '/cpsheet', icon: FaClipboardList, text: 'CP Sheet' },
        { to: '/cptemplates', icon: FaFileCode, text: 'CP Templates' },
        { to: '/cptips', icon: FaLightbulb, text: 'CP Tips and Tricks' },
       
    ];

    const handleTalkToUs = () => {
        window.open('https://t.me/+ioVcWYVASiM5ZTJl', '_blank');
    };

    return (
        <nav className={`navbar ${isMenuOpen ? 'scrolled' : ''}`}>
            <div className="navbar-container">
                <Link to="/" className="navbar-logo">
                    CPHelper
                </Link>
                <div className="menu-icon" onClick={toggleMenu}>
                    {isMenuOpen ? <FaTimes /> : <FaBars />}
                </div>
                <ul className={isMenuOpen ? 'nav-menu active' : 'nav-menu'}>
                    {navLinks.map((link) => (
                        <li key={link.to} className="nav-item">
                            <Link 
                                to={link.to} 
                                className={`nav-link ${location.pathname === link.to ? 'active' : ''}`} 
                                onClick={toggleMenu}
                            >
                                <link.icon /> <span>{link.text}</span>
                            </Link>
                        </li>
                    ))}
                    <li className="nav-item">
                        <button className="talk-to-us-btn" onClick={handleTalkToUs}>
                            <FaTelegram /> <span>Telegram Channel</span>
                        </button>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;