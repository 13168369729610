import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import LandingPage from './components/LandingPage';


import './App.css';

const CPTips = lazy(() => import('./components/CPTips'));
const CPSheet = lazy(() => import('./components/CPSheet'));
const CodeTemplates = lazy(() => import('./components/CodeTemplates'));
const ProblemSolutions = lazy(() => import('./components/ProblemSolutions'));
const AddSolution = lazy(() => import('./components/AddSolution'));
const AdminDashboard = lazy(() => import('./components/Admin/AdminDashboard'));

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="app">
      <Navbar toggleMenu={toggleMenu} />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/problem-solutions/:problemName" element={<ProblemSolutions />} />
          <Route path="/cptemplates" element={<CodeTemplates />} />
          <Route path="/cptips" element={<CPTips />} />
          <Route path="/cpsheet" element={<CPSheet />} />
          <Route path="/add-solution/:problemName" element={<AddSolution />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;